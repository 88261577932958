import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link } from "./link";

const pageLink = (page) =>
  page.value?.externalLink ? page.value.externalLink.value : page.value?.link;

const MegamenuV4 = ({ data }) => {
  const { image, imageC1, imageC2, boxTitle } = data;
  const items = [
    image,
    {
      value: imageC1.value,
      node: imageC1.node,
      content: {
        imageTitle: imageC1.content?.imageC1Title,
        imageText: imageC1.content?.imageC1Text,
        imageLink: imageC1.content?.imageC1Link,
      },
    },
    {
      value: imageC2.value,
      node: imageC2.node,
      content: {
        imageTitle: imageC2.content?.imageC2Title,
        imageText: imageC2.content?.imageC2Text,
        imageLink: imageC2.content?.imageC2Link,
      },
    },
  ];

  return (
    <div className="navigation__megamenu megamenu megamenu--v4 navigation__wrapper">
      <div className="container-fluid flex-fill">
        <div className="row">
          <div className="col-12 col-xl-8">
            <ul className="row navigation__list px-0 mb-xl-2">
              {items?.map((item, j) => (
                <li
                  key={j}
                  className={classNames(
                    "navigation__subitem col-12 px-2 px-xl-0 py-0",
                    j === 0 ? "col-xl-6" : "col-xl-3"
                  )}
                >
                  <Link
                    to={pageLink(item.content?.imageLink)}
                    className="navigation__link d-flex h-100 align-items-start"
                  >
                    <div className="megamenu__item w-100 h-100 d-flex flex-column">
                      {item?.node && (
                        <Img
                          className="d-none d-xl-block"
                          image={getImage(item.node)}
                          alt={item.value?.description || ""}
                        />
                      )}
                      <span className="d-block font-weight-bold mt-xl-3">
                        {item.content?.imageTitle?.value}
                      </span>
                      <div
                        className="megamenu__item-text pb-xl-4 mb-0 mb-xl-4"
                        dangerouslySetInnerHTML={{ __html: item.content?.imageText?.value }}
                      ></div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-xl-4 pl-0 pl-xl-3 pr-0 d-none d-xl-block">
            <div className="megamenu__box">
              <div className="megamenu__box-title">{boxTitle.value}</div>
              <div dangerouslySetInnerHTML={{ __html: boxTitle.content?.boxText?.value }}></div>
              <hr className="megamenu__box-hr"></hr>
              <ul className="megamenu__box-list">
                {boxTitle.content.linkBoxPage.map((page, i) => (
                  <li key={i}>
                    <Link to={pageLink(page)}>{page.content.linkBoxText.value}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MegamenuV4;
export const fragment = graphql`
  fragment MegamenuV4Page on LiferayWebContent {
    ... on LiferayJskLayoutPaginaGenerica {
      link
      title {
        value
      }
    }
    ... on LiferayJskLayoutPaginaPrivacy {
      link
      pageTitle {
        value
      }
    }
    ... on LiferayJskPaginaLink {
      title {
        value
      }
      pageLink: link {
        value
      }
    }
  }
  fragment JskMegamenuV4Fragment on LiferayJskMegamenuV4 {
    liferayFields {
      siteId
      articleId
    }
    boxTitle {
      value
      content {
        boxText {
          value
        }
        linkBoxPage {
          value {
            ...MegamenuV4Page
          }
          content {
            linkBoxText {
              value
            }
          }
        }
      }
    }
    image {
      value {
        description
      }
      node {
        gatsbyImageData(width: 440)
      }
      content {
        imageTitle {
          value
        }
        imageText {
          value
        }
        imageLink {
          value {
            ...MegamenuV4Page
          }
        }
      }
    }
    imageC1 {
      value {
        description
      }
      node {
        gatsbyImageData(width: 200)
      }
      content {
        imageC1Title {
          value
        }
        imageC1Text {
          value
        }
        imageC1Link {
          value {
            ...MegamenuV4Page
          }
        }
      }
    }
    imageC2 {
      value {
        description
      }
      node {
        gatsbyImageData(width: 200)
      }
      content {
        imageC2Title {
          value
        }
        imageC2Text {
          value
        }
        imageC2Link {
          value {
            ...MegamenuV4Page
          }
        }
      }
    }
  }
`;
