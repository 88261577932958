import React from "react";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import Cta from "./elements/cta";

const SlideHeaderContent = ({ slide, titolo, descrizione, modello, cta = {}, variant }) => {
  return (
    <section
      className={classNames("section slide-header", variant ? `slide-header--${variant}` : "", modello?.value ? `slide-header--${modello.value}`:'slide-header--slideheader')}
    >
      <Img
        image={getImage(slide.node)}
        alt={slide.value?.description || ""}
        className="slide-header__img"
      />
      <div className="slide-header__content">
        <h1 dangerouslySetInnerHTML={{ __html: titolo.value }} className="slide-header__title"></h1>
        {descrizione?.value && (
          <div
            className="slide-header__descrizione text-white"
            dangerouslySetInnerHTML={{ __html: descrizione.value }}
          ></div>
        )}
        {Object.entries(cta).length ? <Cta cta={cta} /> : null}
      </div>
    </section>
  );
};

export default SlideHeaderContent;
