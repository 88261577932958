import React from "react";
import { graphql } from "gatsby";
import { Link } from "./link";
import Icon from "./elements/icon-svg";

const TopMenu = ({ data }) => {
  const { title, spazioClientiTitle } = data;

  const spazioClienti =
    spazioClientiTitle?.content?.spazioClientiPage?.value?.link ||
    (spazioClientiTitle?.content?.spazioClientiLink?.value &&
      spazioClientiTitle?.content?.spazioClientiLink?.value !== "#")
      ? spazioClientiTitle?.content?.spazioClientiPage?.value?.link ||
        spazioClientiTitle?.content?.spazioClientiLink?.value
      : null;

  const envelopeElement = title?.find((item) => item?.content?.fixedEnvelopeButton?.value);
  const envelopeButton =
    envelopeElement?.content?.page?.value?.link || envelopeElement?.content?.link?.value;

  return (
    <>
      <div className="d-inline-flex justify-content-end align-items-center px-0 header__top-right">
        {title?.map((item, i) => (
          <Link
            to={item.content?.page?.value?.link || item.content?.link?.value}
            className="header__link d-none d-xl-inline-block ml-3"
            style={{ color: item.content?.titleColor?.value }}
            key={i}
          >
            {item.value}
          </Link>
        ))}
        {spazioClienti && (
          <Link
            to={spazioClienti}
            className="header__link header__link--spazio-clienti d-inline-block ml-3"
          >
            <Icon name="user-solid" />
          </Link>
        )}
      </div>
      {envelopeButton && (
        <Link to={envelopeButton} className="envelope-link">
          <i className="nj-icon nj-icon-envelope nj-icon--circle size-60"></i>
        </Link>
      )}
    </>
  );
};

export default TopMenu;
export const fragment = graphql`
  fragment JskTopMenuFragment on LiferayJskTopMenu {
    title {
      value
      content {
        link {
          value
        }
        page {
          value {
            ... on LiferayJskLayoutPaginaGenerica {
              link
            }
            ... on LiferayJskPaginaLink {
              externalLink: link {
                value
              }
            }
          }
        }
        titleColor {
          value
        }
        fixedEnvelopeButton {
          value
        }
      }
    }
    spazioClientiTitle {
      value
      content {
        spazioClientiLink {
          value
        }
        spazioClientiPage {
          value {
            ... on LiferayJskLayoutPaginaGenerica {
              link
            }
            ... on LiferayJskPaginaLink {
              externalLink: link {
                value
              }
            }
          }
        }
      }
    }
  }
`;
