import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({
  description,
  ogDescription,
  path,
  ogImage,
  meta,
  keywords,
  robots,
  ogType = "website",
  title = "",
}) {
  const { site } = useStaticQuery(
    graphql`
      query DefaultSEOQuery {
        site {
          siteMetadata {
            title
            siteUrl
            description
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      title={title}
      titleTemplate={`%s - ENGIE Imprese`}
      htmlAttributes={{ lang: "it" }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: ogDescription || metaDescription,
        },
        {
          property: `og:type`,
          content: ogType,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: ogDescription || metaDescription,
        },
      ]
        .concat(
          path
            ? [
                {
                  name: `og:url`,
                  content: `${site.siteMetadata.siteUrl}${path}`,
                },
                {
                  name: `twitter:url`,
                  content: `${site.siteMetadata.siteUrl}${path}`,
                },
              ]
            : []
        )
        .concat(
          ogImage?.resize
            ? [
                {
                  property: `og:image`,
                  content: ogImage.resize.src,
                },
                {
                  property: `twitter:image`,
                  content: ogImage.resize.src,
                },
              ]
            : []
        )
        .concat(
          ogImage?.resize
            ? [
                {
                  property: "og:image:width",
                  content: ogImage.resize.width,
                },
                {
                  property: "og:image:height",
                  content: ogImage.resize.height,
                },
                {
                  property: "twitter:image:width",
                  content: ogImage.resize.width,
                },
                {
                  property: "twitter:image:height",
                  content: ogImage.resize.height,
                },
              ]
            : []
        )
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : []
        )
        .concat(
          robots
            ? { name: "robots", value: robots.join(", ") }
            : { name: "robots", value: "index,follow" }
        )
        .concat(meta)}
      link={
        path
          ? [
              {
                rel: "canonical",
                href: `${site.siteMetadata.siteUrl}${path}`,
              },
            ]
          : []
      }
    ></Helmet>
  );
}

SEO.defaultProps = {
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string,
};

export default SEO;
