import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link } from "./link";
import Icon from "./elements/icon-svg";

const pageLink = (page) =>
  page.value?.externalLink ? page.value.externalLink.value : page.value?.link;

const MegamenuV7 = ({ data }) => {
  const { title, imgDx } = data;

  return (
    <div
      className={classNames(
        "navigation__megamenu navigation__megamenu--v7 megamenu megamenu--v7",
        imgDx && "navigation__wrapper"
      )}
    >
      <div className="container flex-fill">
        <div className="row">
          <div className={classNames("col-12", imgDx && "col-xl-9")}>
            <ul className="row navigation__list px-0">
              {title?.map((child, j) => (
                <li
                  key={j}
                  className={classNames(
                    "navigation__subitem col-12 px-2 py-2",
                    imgDx ? "col-xl-4" : "col-xl-3"
                  )}
                >
                  <Link
                    to={pageLink(child.content.page)}
                    className="navigation__link d-flex align-items-start"
                  >
                    <div className="w-100 d-flex flex-xl-column align-items-center align-items-xl-start">
                      <div className="">
                        <span className="d-block font-weight-bold">{child.value}</span>
                        {child.content.text?.value ? (
                          <p className="navigation__description mt-1">{child.content.text.value}</p>
                        ) : null}
                      </div>
                      <Icon
                        name="chevron-right"
                        cls="material-svg arrow ml-auto ml-xl-0 mt-0 mt-xl-2"
                      />
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {imgDx?.content?.imgLinkText?.content?.pageDx && (
        <div className="banner">
          <Link
            to={pageLink(imgDx.content.imgLinkText.content.pageDx)}
            className="banner__box h-100"
          >
            <Img
              className="banner__img"
              image={getImage(imgDx.node)}
              alt={imgDx.value?.description || ""}
            />
            <div className="banner__gradient-box position-relative text-white h-100 d-flex flex-column justify-content-center">
              {imgDx.content?.imgTitle?.value && (
                <div className="banner__title">
                  <span className="d-lg-block">{imgDx.content.imgTitle.value} </span>
                </div>
              )}
              <div
                className="banner__subtitle mt-1 mb-3"
                dangerouslySetInnerHTML={{ __html: imgDx.content.imgText.value }}
              ></div>
              <div className="d-flex align-items-center">
                <div className="banner__link border-bottom border-white"></div>
                <Icon name="chevron-right" cls="material-svg arrow" />
              </div>
            </div>
          </Link>
        </div>
      )}
    </div>
  );
};

export default MegamenuV7;
export const fragment = graphql`
  fragment JskMegamenuV7Fragment on LiferayJskMegamenuV7 {
    liferayFields {
      siteId
      articleId
    }
    title {
      value
      content {
        page {
          value {
            ... on LiferayJskLayoutPaginaGenerica {
              title {
                value
              }
              link
            }
            ... on LiferayJskPaginaLink {
              title {
                value
              }
              externalLink: link {
                value
              }
            }
          }
        }
        text {
          value
        }
      }
    }
    imgDx {
      value {
        description
      }
      node {
        gatsbyImageData(height: 400)
      }
      content {
        imgTitle {
          value
        }
        imgText {
          value
        }
        imgLinkText {
          value
          content {
            pageDx {
              value {
                __typename
                ... on LiferayJskLayoutPaginaGenerica {
                  title {
                    value
                  }
                  link
                }
              }
            }
          }
        }
      }
    }
  }
`;
