import React, { useState, useCallback } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "./link";
import SitesSelect from "./sites-select";
import Navigation from "./navigation";
import useBodyClass from "../utility/useBodyClass";
import useClickOut from "../utility/useClickOut";
import logo from "../images/logo-engie-blue.svg";
import logoCompact from "../images/logo-engie-small-blue.svg";
import TopMenu from "./top-menu";

const Header = ({ siteTitle, navMenu, topMenu, theme, location }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = useCallback(() => setMenuOpen((value) => !value), []);
  const [selectOpen, setSelectOpen] = useState(false);
  const toggleSelect = useCallback(() => setSelectOpen((value) => !value), []);

  useBodyClass(menuOpen || selectOpen, "block-scroll-mobile");

  const menuRef = useClickOut(menuOpen, setMenuOpen);
  const selectRef = useClickOut(selectOpen, setSelectOpen);

  return (
    <div className={classNames("header", theme === "old" && "header--old")}>
      <div className="header__group">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="header__top d-flex align-items-center">
                <div className="d-inline-flex align-items-center pl-0 header__top-left"></div>
                <div className="header__logo text-center">
                  <Link className="header__link h-100" to="/">
                    <img src={logo} className="h-100" alt={siteTitle} />
                  </Link>
                </div>
                {topMenu ? <TopMenu data={topMenu} /> : <div className="flex-fill"></div>}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 position-static">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-inline-flex align-items-center w-100">
                  <div className="header__logo-compact mr-3">
                    <Link className="header__link w-100" to="/">
                      <img src={logoCompact} className="w-100" alt={siteTitle} />
                    </Link>
                  </div>
                  <SitesSelect
                    open={selectOpen}
                    onClick={toggleSelect}
                    location={location}
                    ref={selectRef}
                  />
                </div>
                {navMenu && (
                  <Navigation navMenu={navMenu} open={menuOpen} ref={menuRef} location={location} />
                )}
                <div className="header__buttons d-inline-flex align-items-center ml-auto">
                  <button
                    className={classNames("hamburger d-xl-none", {
                      open: menuOpen,
                    })}
                    aria-label="Navigation menu"
                    onClick={toggleMenu}
                  >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
