import React from "react";
import classNames from "classnames";
import icons_svg from "../../images/svg-icons.svg";

function Icon({ name, cls, style, alt }) {
  return (
    <svg className={classNames("icon", cls)} style={style} alt={alt}>
      <use href={icons_svg + "#" + name}></use>
    </svg>
  );
}

export default Icon;
