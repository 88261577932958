import React, { useCallback, useState } from "react";
import useClickOut from "../../utility/useClickOut";
import { Link } from "../link";

const FooterSites = ({ location }) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = useCallback(() => setOpen((value) => !value), []);
  const ref = useClickOut(open, setOpen);
  const isCondomini = location.pathname?.startsWith("/condomini/");

  return (
    <div ref={ref} className={`footer-sites ${open ? "footer-sites--open " : ""}d-inline-block`}>
      <button
        className="footer-sites__btn text-uppercase text-blue-corporate font-weight-bold"
        onClick={toggleOpen}
      >
        {isCondomini ? "Condomini" : "Imprese"}
      </button>
      <div className="footer-sites__content text-uppercase">
        <a className="footer-sites__link" href="https://casa.engie.it/">
          Casa
        </a>
        {isCondomini ? (
          <Link className="footer-sites__link" to="/">
            Imprese
          </Link>
        ) : (
          <Link className="footer-sites__link" to="/condomini/">
            Condomini
          </Link>
        )}
        <a className="footer-sites__link" href="https://www.engie.it/">
          Chi siamo
        </a>
        <a className="footer-sites__link" href="https://enti.engie.it/">
          Enti
        </a>
      </div>
    </div>
  );
};

export default FooterSites;
