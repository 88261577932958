import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useStaticQuery, graphql } from "gatsby";
import useScroll from "../utility/useScroll";
import Header from "./header";
import Footer from "./footer";
import PreviewBar from "./preview/preview-bar";

const Layout = ({ banner, children, location, navMenu, topMenu, theme = "new" }) => {
  const { site } = useStaticQuery(
    graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  const scrolled = useScroll(60);

  return (
    <div
      className={classNames("wrapper", {
        "wrapper--scrolled": scrolled,
      })}
    >
      <Header
        siteTitle={site.siteMetadata.title}
        hasBanner={banner}
        navMenu={navMenu}
        topMenu={topMenu}
        theme={theme}
        location={location}
      />
      {process.env.EDIT_MODE === "true" && <PreviewBar />}
      {children}
      <Footer location={location} />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
