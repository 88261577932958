import React from "react";
import { Link as GatsbyLink } from "gatsby";

// Fine tune this on project needs
const INTERNAL_REGEX = new RegExp(`^(/|\\?)(?!/)(?!documents)`);

const GlobalLink = ({ to, children, ...props }) => {
  if (INTERNAL_REGEX.test(to)) {
    const { className, activeClassName, partiallyActive, style, activeStyle } = props;

    // Gestione activeClassName indipendente da slash finale o meno
    const getProps = ({ href, location }) => {
      const pathname = location.pathname;
      let active = partiallyActive && pathname.startsWith(href.endsWith("/") ? href : `${href}/`);
      if (!active) {
        const strippedHref = href.endsWith("/") ? href.substring(0, href.length - 1) : href;
        const strippedPathname = pathname.endsWith("/")
          ? pathname.substring(0, pathname.length - 1)
          : pathname;
        active = strippedHref === strippedPathname;
      }
      if (active) {
        return {
          className: [className, activeClassName].filter(Boolean).join(" "),
          style: { ...style, ...activeStyle },
        };
      }
      return null;
    };
    return (
      <GatsbyLink getProps={getProps} to={to} {...props}>
        {props.dangerouslySetInnerHTML ? undefined : children}
      </GatsbyLink>
    );
  }
  // Remove activeClassName prop from external links
  const { activeClassName, target, ...other } = props;
  const _target = target ? target : to?.startsWith("#") ? "_self" : "_blank";
  return (
    <a href={to} {...other} target={_target} rel="noopener noreferrer">
      {props.dangerouslySetInnerHTML ? undefined : children}
    </a>
  );
};

export const Link = GlobalLink;
