import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link } from "./link";

const pageLink = (page) =>
  page.value?.externalLink ? page.value.externalLink.value : page.value?.link;

const MegamenuV5 = ({ data }) => {
  const { linkTitle, image } = data;

  return (
    <div
      className={classNames(
        "navigation__megamenu megamenu megamenu--v5",
        image && "navigation__wrapper"
      )}
    >
      <div className="container-fluid flex-fill">
        <div className="row">
          <div className={classNames("col-12", image && "col-xl-8 pl-xl-0")}>
            <ul className="row navigation__list px-0 pr-xl-2 pt-xl-3">
              {linkTitle?.map((child, j) => (
                <li
                  key={j}
                  className={classNames(
                    "navigation__subitem col-12 pl-2 pt-xl-0 pb-0",
                    image ? "col-xl-4" : "col-xl-3"
                  )}
                >
                  <Link
                    to={pageLink(child.content?.pageLink)}
                    className="navigation__link d-flex align-items-start"
                  >
                    <div className="megamenu__item w-100">
                      <div className="w-100 d-flex align-items-center">
                        {child.content?.logo?.node && (
                          <Img
                            className="megamenu__icon"
                            image={getImage(child.content.logo.node)}
                            alt={child.content.logo.value?.description || ""}
                          />
                        )}
                        <span className="d-block font-weight-bold">{child.value}</span>
                      </div>
                      <div
                        className="megamenu__item-text"
                        dangerouslySetInnerHTML={{ __html: child.content?.linkText?.value }}
                      ></div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          {image && (
            <div className="col-xl-4 px-0">
              <div className="megamenu__box">
                <Link
                  to={pageLink(image.content?.productLinkImage)}
                  aria-label={image.content?.imageTitle?.value}
                >
                  <Img image={getImage(image.node)} alt={image.value?.description || ""} />
                </Link>
                {image.content?.imageTitle?.value && (
                  <h4 className="megamenu__img-title">{image.content.imageTitle.value}</h4>
                )}
                {image.content?.imageText?.value && (
                  <div
                    className="megamenu__img-text"
                    dangerouslySetInnerHTML={{ __html: image.content.imageText.value }}
                  ></div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MegamenuV5;
export const fragment = graphql`
  fragment JskMegamenuV5Fragment on LiferayJskMegamenuV5 {
    liferayFields {
      siteId
      articleId
    }
    linkTitle {
      value
      content {
        linkText {
          value
        }
        pageLink {
          value {
            __typename
            ... on LiferayJskLayoutPaginaGenerica {
              title {
                value
              }
              link
            }
            ... on LiferayJskPaginaLink {
              title {
                value
              }
              externalLink: link {
                value
              }
            }
          }
        }
        logo {
          value {
            description
          }
          node {
            gatsbyImageData(width: 32, layout: FIXED)
          }
        }
      }
    }
    image {
      value {
        description
      }
      node {
        gatsbyImageData(width: 410)
      }
      content {
        imageTitle {
          value
        }
        imageText {
          value
        }
        productLinkImage {
          value {
            __typename
            ... on LiferayJskLayoutPaginaGenerica {
              title {
                value
              }
              link
            }
            ... on LiferayJskPaginaLink {
              title {
                value
              }
              externalLink: link {
                value
              }
            }
          }
        }
      }
    }
    linkSxPage {
      value {
        __typename
        ... on LiferayJskLayoutPaginaGenerica {
          title {
            value
          }
          link
        }
        ... on LiferayJskPaginaLink {
          title {
            value
          }
          externalLink: link {
            value
          }
        }
      }
      content {
        linkSxText {
          value
        }
      }
    }
    linkDxPage {
      value {
        __typename
        ... on LiferayJskLayoutPaginaGenerica {
          title {
            value
          }
          link
        }
        ... on LiferayJskPaginaLink {
          title {
            value
          }
          externalLink: link {
            value
          }
        }
      }
      content {
        linkDxText {
          value
        }
      }
    }
  }
`;
