import React, { useCallback } from "react";
import { graphql } from "gatsby";
import { GatsbyImage as Img, getImage } from "gatsby-plugin-image";
import classNames from "classnames";
import { Link } from "./link";

const pageLink = (page) =>
  page.value?.externalLink ? page.value.externalLink.value : page.value?.link;

const MegamenuV6 = ({ data }) => {
  const { immagine } = data;
  const items = immagine?.slice(0, 5);

  const getItemImage = useCallback(
    (item) => {
      let node = item.node;
      if (items.length > 4) {
        node = item.node5;
      } else if (items.length > 3) {
        node = item.node4;
      } else if (items.length > 2) {
        node = item.node3;
      }
      return getImage(node);
    },
    [items]
  );

  return (
    <div className="navigation__megamenu megamenu megamenu--v6">
      <div className="container-fluid flex-fill">
        <div className="row">
          <div className="col-12">
            <ul className="row navigation__list px-0">
              {items?.map((item, i) => (
                <li
                  key={i}
                  className={classNames(
                    "navigation__subitem col-12 px-0 pl-xl-2 pt-xl-2 pb-0 pb-xl-2",
                    `col-xl-${items.length === 5 ? "2" : 12 / items.length}`,
                    i === 0 && items.length === 5 && "offset-xl-1"
                  )}
                >
                  <Link
                    to={
                      item?.content?.immagineLinkEsterno?.value ||
                      item?.content?.immagineDocumento?.node?.publicURL ||
                      pageLink(item.content?.immagineLink)
                    }
                    className="navigation__link d-flex align-items-start"
                  >
                    <div className="megamenu__item w-100 d-flex flex-column">
                      {item.node && (
                        <Img
                          className="megamenu__img d-none d-xl-block"
                          image={getItemImage(item)}
                          alt={item.value?.description || ""}
                        />
                      )}
                      <span className="megamenu__item-title d-block font-weight-bold">
                        {item.content?.immagineTitolo?.value}
                      </span>
                      <div
                        className="megamenu__item-text"
                        dangerouslySetInnerHTML={{ __html: item.content?.immagineTesto?.value }}
                      ></div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MegamenuV6;
export const fragment = graphql`
  fragment MegamenuV6Page on LiferayWebContent {
    ... on LiferayJskLayoutPaginaGenerica {
      link
      title {
        value
      }
    }
    ... on LiferayJskLayoutPaginaPrivacy {
      link
      pageTitle {
        value
      }
    }
    ... on LiferayJskPaginaLink {
      title {
        value
      }
      pageLink: link {
        value
      }
    }
  }
  fragment JskMegamenuV6Fragment on LiferayJskMegamenuV6 {
    liferayFields {
      siteId
      articleId
    }
    immagine {
      value {
        description
      }
      node {
        gatsbyImageData(width: 660)
      }
      node3: node {
        gatsbyImageData(width: 420)
      }
      node4: node {
        gatsbyImageData(width: 300)
      }
      node5: node {
        gatsbyImageData(width: 176)
      }
      content {
        immagineTitolo {
          value
        }
        immagineTesto {
          value
        }
        immagineLink {
          value {
            ...MegamenuV6Page
          }
        }
        immagineLinkEsterno {
          value
        }
        immagineDocumento {
          node {
            publicURL
          }
        }
      }
    }
    #linkSx {
    #  value {
    #    ...MegamenuV6Page
    #  }
    #  content {
    #    linkSxTesto {
    #      value
    #    }
    #  }
    #}
  }
`;
