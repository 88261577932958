import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Link } from "../components/link";
import FooterSites from "./elements/footer-sites";
import { onetrustShowSettings } from "../utility/onetrust";
import Icon from "./elements/icon-svg";

const Footer = ({ location }) => {
  const { menuFooter } = useStaticQuery(graphql`
    fragment FooterPage on LiferayWebContent {
      ... on LiferayJskLayoutPaginaGenerica {
        link
        title {
          value
        }
      }
      ... on LiferayJskLayoutPaginaPrivacy {
        link
        pageTitle {
          value
        }
      }
      ... on LiferayJskPaginaLink {
        title {
          value
        }
        pageLink: link {
          value
        }
      }
    }
    {
      menuFooter: liferayJskMenuFooter {
        firstColLinks {
          value {
            ...FooterPage
          }
          content {
            firstColLabel {
              value
            }
          }
        }
        secondColLinks {
          value {
            ...FooterPage
          }
          content {
            secondColLabel {
              value
            }
          }
        }
      }
    }
  `);

  return (    

    <div className="footer">
      <div className="footer__content">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-5 col-lg-6">
              <span className="footer__label mr-3">Sei in:</span>
              <FooterSites location={location} />
            </div>
            <div className="col-12 pt-5 pt-md-0 pb-5 pb-md-0 col-md-4 col-lg-3">
              <div className="container">
                <div className="row">
                  <div className="col-6 pl-0">
                    <ul className="list-unstyled">
                      {menuFooter?.firstColLinks?.map((item, i) => (
                        <li key={i}>
                          <Link
                            to={item.value?.link || item.value?.pageLink?.value}
                            className="footer__link text-blue-corporate"
                          >
                            {item.content?.firstColLabel?.value ||
                              item.value?.title?.value ||
                              item.value?.pageTitle?.value}
                          </Link>
                        </li>
                      ))}
                      <li>
                        <button
                          className="footer__link text-blue-corporate"
                          onClick={onetrustShowSettings}
                        >
                          Impostazioni cookies
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 pl-0">
                    <ul className="list-unstyled">
                      {menuFooter?.secondColLinks?.map((item, i) => (
                        <li key={i}>
                          <Link
                            to={item.value?.link || item.value?.pageLink?.value}
                            className="footer__link text-blue-corporate"
                          >
                            {item.content?.secondColLabel?.value ||
                              item.value?.title?.value ||
                              item.value?.pageTitle?.value}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3 pr-sm-7 pr-md-2 pb-5 pb-md-0 d-flex justify-content-between">
              <a
                href="https://www.facebook.com/engieitalia"
                className="footer__icon"
                aria-label="Facebook"
              >
                <Icon name="facebook-solid" />
              </a>
              <a
                href="https://www.instagram.com/engieitalia"
                className="footer__icon"
                aria-label="Instagram"
              >
                <Icon name="instagram" />
              </a>
              <a
                href="https://www.tiktok.com/@engieitalia"
                className="footer__icon"
                aria-label="TikTok"
              >
                <Icon name="tiktok" />
              </a>
              <a
                href="https://twitter.com/ENGIEitalia"
                className="footer__icon"
                aria-label="Twitter"
              >
                <Icon name="twitter-x" />
              </a>
              <a
                href="https://www.youtube.com/c/ENGIEitalia"
                className="footer__icon"
                aria-label="Youtube"
              >
                <Icon name="youtube" />
              </a>
              <a
                href="https://www.linkedin.com/company/engie-italia"
                className="footer__icon"
                aria-label="Linkedin"
              >
                <Icon name="linkedin" />
              </a>
            </div>
          </div>
          <div className="row">
            <div className="col-9 col-sm-5 col-lg-4 col-xl-3">
              <span className="footer__info">ENGIE ITALIA S.p.A. - P. IVA 06289781004</span>
            </div>
            <div className="col-3 pr-0 pr-sm-2 col-lg-2">
              <Link to="/mappa-sito/" className="footer__info">
                Mappa sito
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
