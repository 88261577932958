import React from "react";
import classNames from "classnames";
import { Link } from "../link";

function Cta({ cta }) {
  const {
    value: label,
    content: { colorebottone, ctaLink, webContent },
  } = cta;

  return ctaLink?.value && ctaLink?.value !== "#" ? (
    <Link
      to={ctaLink.value}
      className={classNames(
        "cta-link",
        colorebottone?.value?.[0] && `btn-color-${colorebottone.value[0]}`
      )}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  ) : webContent?.value.link || webContent?.value.externalLink?.value ? (
    <Link
      to={`${webContent?.value.link || webContent?.value.externalLink?.value}`}
      className={classNames(
        "cta-link",
        colorebottone?.value?.[0] && `btn-color-${colorebottone.value[0]}`
      )}
      dangerouslySetInnerHTML={{ __html: label }}
    />
  ) : null;
}

export default Cta;
