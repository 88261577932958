import React, { forwardRef, useState, useEffect } from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { Link } from "./link";
import MegamenuV4 from "./megamenu-v4";
import MegamenuV5 from "./megamenu-v5";
import MegamenuV6 from "./megamenu-v6";
import MegamenuV7 from "./megamenu-v7";
import useClickOut from "../utility/useClickOut";
import Icon from "./elements/icon-svg";

const Megamenu = ({ data, location }) => {
  switch (data.__typename) {
    case "LiferayJskMegamenuV4":
      return <MegamenuV4 data={data} location={location} />;
    case "LiferayJskMegamenuV5":
      return <MegamenuV5 data={data} location={location} />;
    case "LiferayJskMegamenuV6":
      return <MegamenuV6 data={data} location={location} />;
    case "LiferayJskMegamenuV7":
      return <MegamenuV7 data={data} location={location} />;
    default:
      return null;
  }
};

const menuSmartphone = [
  {
    label: "Carbon Footprint",
    link: "https://footprint.engie.it/",
    style: "color-light-blue",
  },
  {
    label: "Contatti",
    link: "/contatti/",
  },
];

const Navigation = forwardRef(({ navMenu, open, location }, ref) => {
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [openItem, setOpenItem] = useState(null);

  const itemClick = (e, item) => {
    if (item.value?.megamenu?.value) {
      e.preventDefault();
      setOpenItem(item);
      setSubmenuOpen(openItem !== item || !submenuOpen);
    }
  };

  useEffect(() => {
    if (!open) {
      setSubmenuOpen(false);
    }
  }, [open]);

  const subMenuRef = useClickOut(submenuOpen, setSubmenuOpen);

  return (
    <div
      ref={ref}
      className={classNames("navigation w-100 w-xl-auto", {
        "navigation--open": open,
        "navigation--subopen": submenuOpen,
      })}
    >
      <div className="navigation__container" ref={subMenuRef}>
        <ul className="navigation__list d-flex flex-column flex-xl-row align-items-center justify-content-between">
          {navMenu.pages.map((item, i) => (
            <li
              className={classNames("navigation__item w-100 w-xl-auto", {
                open: item === openItem,
              })}
              key={i}
            >
              <div className="d-flex d-xl-inline-flex align-items-center">
                <Link
                  className={`navigation__link first-level`}
                  to={item.value?.link || item.value?.externalLink?.value}
                >
                  <span className="font-weight-bold">
                    {item.content?.menuLabel?.value || item.value?.title?.value}
                  </span>
                </Link>
                {item.value?.megamenu?.value && (
                  <button
                    className="navigation__toggle ml-auto d-xl-none"
                    aria-label="Toggle button"
                    onClick={(e) => itemClick(e, item)}
                  >
                    <Icon name="chevron-right" cls="material-svg text-blue-corporate" />
                  </button>
                )}
                {item.value?.megamenu?.value && (
                  <button
                    className={classNames("navigation__toggle d-none d-xl-inline-flex", {
                      "navigation__toggle--open": item === openItem && submenuOpen,
                    })}
                    aria-label="Toggle button"
                    onClick={(e) => itemClick(e, item)}
                  >
                    <Icon name="expand-more" cls="material-svg" />
                  </button>
                )}
              </div>
              {item.value?.megamenu?.value && (
                <div
                  className={classNames("d-flex flex-column navigation__submenu", {
                    "navigation__submenu--open": openItem === item && submenuOpen,
                    "navigation__submenu--content": !!item.value?.megamenu?.value,
                    "navigation__submenu--v5":
                      item.value?.megamenu?.value?.__typename === "LiferayJskMegamenuV5",
                    "navigation__submenu--v7":
                      item.value?.megamenu?.value?.__typename === "LiferayJskMegamenuV7",
                  })}
                >
                  <button
                    className="navigation__subbutton px-2 py-3 mb-xl-3 d-flex align-items-center d-xl-none"
                    onClick={() => setSubmenuOpen(false)}
                  >
                    <Icon name="chevron-left" cls="material-svg text-blue-corporate d-xl-none" />
                    <span className="text-blue-corporate font-weight-bold">
                      {openItem && "Indietro"}
                    </span>
                  </button>
                  <Megamenu
                    data={item.value?.megamenu?.value}
                    openItem={openItem}
                    submenuOpen={submenuOpen}
                    setSubmenuOpen={setSubmenuOpen}
                    location={location}
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
        <div className="container-fluid mt-4 d-xl-none">
          <div className="row">
            {menuSmartphone.map((item, i) => (
              <div key={i} className="mt-3 col-12 px-1">
                <Link
                  to={item.link}
                  className={classNames("header__link header__link--big", item.style)}
                >
                  {item.label}
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
});

export default Navigation;
export const fragment = graphql`
  fragment MegamenuFragment on LiferayWebContent {
    __typename
    ...JskMegamenuV4Fragment
    ...JskMegamenuV5Fragment
    ...JskMegamenuV6Fragment
    ...JskMegamenuV7Fragment
  }
  fragment MenuNavigazioneFragment on LiferayJskMenuNavigazione {
    pages {
      value {
        ... on LiferayJskLayoutPaginaGenerica {
          title {
            value
          }
          megamenu {
            value {
              ...MegamenuFragment
            }
          }
          link
        }
        ... on LiferayJskPaginaLink {
          title {
            value
          }
          externalLink: link {
            value
          }
        }
      }
      content {
        menuLabel {
          value
        }
      }
    }
  }
`;
